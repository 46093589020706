import { HomeScreen } from "@chapter/nightingale-base";

export default function Home() {
    return (
        <>
            <div>
                <main>
                    <HomeScreen />
                </main>
            </div>
        </>
    );
}
